/* eslint-disable no-underscore-dangle, id-blacklist, id-match */
import { Timestamp } from '@angular/fire/firestore';

import { FilterOption } from 'src/app/components/filter-button/filter-button.component';
import { USER_STATUS_ACTIVE, USER_STATUS_INACTIVE } from 'src/app/constants';
import { AgentActivity } from 'src/services/ai-tester.service.js';

import { ChannelType, INTAKE_MODES, IntakeMode, IntakeSettings } from './channels';
import { EntityType } from './entities';
import { Project } from './projects';
import { UserGroup } from './user-groups';
import { TenantUserRole } from './users';

export interface SlackUserGroup {
  id: string;
  slackId?: string;
  slackHandle?: string;
  name: string;
  description?: string;
  userIds?: string[];
}

export interface Customer {
  id: string;
  name: string;
  slackChannelId: string;
  slackTeamId: string;
  followerCount: number;
  tags?: Tag[];
  supportSteps?: SupportStep[];
  adminEmails?: string[];
  adminIds?: string[];
  slackChannel?: SlackChannel;
  isInternal?: boolean;
  primarySupportAssigneeId?: string;
  primarySupportAssigneeType?: string;
  secondarySupportAssigneeId?: string;
  secondarySupportAssigneeType?: string;
  slackTeams: { id: string; name: string; icon?: string }[];
  externalCrmMetadata?: any;
  createdBy: string;
  createdAt: Date;
  supportStepRuleset?: any;
  supportStepRulesetId?: string;
  customFields?: any[];
  project: Partial<Project>;
  projectId?: string;
  defaultTriageChannelId?: string;
  inboundEmailRule?: Partial<InboundEmailRule>;
  externalChatConnection?: Partial<ExternalChatConnection>;
  widget?: Partial<Widget>;
  type: ChannelType;
}

export interface Metadata {
  creatorName: string;
  creatorPhone: string;
}

export interface Profile {
  autoAddBot?: boolean | null;
  autoAddBotSettings?: {
    condition: string;
    regex?: string;
    channelType?: string;
    isInternalOverride?: boolean | null;
  };
  deleted?: boolean;
  status?: typeof USER_STATUS_ACTIVE | typeof USER_STATUS_INACTIVE;
  deviceToken: string;
  photo?: string;
  photoPath?: string;
  phoneNumber?: string;
  email?: string;
  createdAt?: Date;
  updatedAt?: Date;
  updatedBy?: string;
  lastSeenAt?: Date;
  name?: string;
  objectId?: string;
  zoomEnabled?: boolean;
  googleCalendarEnabled?: boolean;
  pronoun?: string;
  description?: string;
  customFields?: any[];
  tenantId?: string;
  additionalIdpFields?: Record<string, unknown>;
  id?: string;
  title?: string;
  organization?: string;
  division?: string;
  costCenter?: string;
  employeeNumber?: string;
  employeeType?: string;
  department?: string;
  streetAddress?: string;
  country?: string;
  region?: string;
  locality?: string;
  postalCode?: string;
  slackId?: string;
  isExternal?: boolean;
  widgetVerificationHash?: string;
  helpCenterVerificationHash?: string;
  awayUntil?: Date;
  backupUserId?: string;
  defaultInboxView?: string;
  isAutoAssignable?: boolean | null;
  emailSignature?: string | null;
  notificationSettings?: {
    conversationAssignedToMe?: boolean;
    conversationCreated?: boolean;
  } | null;
  slackInboxState?: {
    sort?: string;
    currentButtonId?: string;
  };
  manager?: {
    displayName: string;
  };
  slackTeam?: {
    id: string;
    name: string;
  };
  role?: TenantUserRole;
  invitedAt?: Date;
}

export class Upload {
  $key: string;
  file: File;
  path: string;
  name: string;
  url: string;
  progress: number;
  createdAt: Date = new Date();
  metadata?: {
    uploadId: string;
    tenantId: string;
    createdBy: string;
    filename: string;
    status: string;
    type: string;
    objectId: string;
    muteWelcomeEmail?: string; // firebase storage only wants strings
  };

  constructor(file: File) {
    this.file = file;
  }
}

export const AUTH_PROVIDER_PASSWORD = 'password';
export const AUTH_PROVIDER_EMAIL_LINK = 'emailLink';
export const AUTH_PROVIDER_GSUITE = 'gsuite';
export const AUTH_PROVIDER_OKTA_OIDC = 'okta-oidc';
export const AUTH_PROVIDER_OKTA_SAML = 'okta-saml';
export const AUTH_PROVIDER_PHONE = 'phone';
export const AUTH_PROVIDER_MICROSOFT = 'microsoft.com';

export type AuthProvider =
  | typeof AUTH_PROVIDER_PASSWORD
  | typeof AUTH_PROVIDER_GSUITE
  | typeof AUTH_PROVIDER_OKTA_OIDC
  | typeof AUTH_PROVIDER_OKTA_SAML
  | typeof AUTH_PROVIDER_PHONE
  | typeof AUTH_PROVIDER_MICROSOFT;

export interface WebhookSubscription {
  id: string;
  url: string;
  enabled: boolean;
  consecutiveFailures: number;
}

export interface WebhookConfig {
  secret: string;
}

export const AGENT_HANDOFF_MODES = {
  AUTOMATIC: 'automatic',
  PROMPT: 'prompt',
} as const;

export type AgentHandoffMode = (typeof AGENT_HANDOFF_MODES)[keyof typeof AGENT_HANDOFF_MODES];

export interface AiFeatures {
  autoTagging?: boolean;
  autoTitle?: boolean;
  autoTicketType?: boolean;
  autoSummarize?: boolean;
  postSimilarConversations?: boolean;
  suggestResponses?: boolean;
  autoPrioritize?: boolean;
  customerSentiment?: boolean;
  emailThreadAnalysis?: boolean;
  temperature?: number;
  agentStaticPrefix?: string;
  agentStaticPrefixOutsideWorkingHours?: string;
  passiveAutoresponseAnalytics?: boolean;
  maxAutoTags?: number;
}

interface AiFeedback {
  message: string;
  type: string;
}

export interface AiResponse {
  id: string;
  tenantId: string;
  conversationId: string;
  conversation?: Conversation;
  customerId: string;
  query: string;
  response: string;
  articleIds: [string];
  createdAt: Date;
  updatedAt?: Date;
  feedbacks: AiFeedback[];
  userId: string;
  trigger: string;
  messageTs?: string;
  responseStatus: string;
}

export interface AgentTopicTools {
  topicId: string;
  automationId: string;
  description: string;
  approverId?: string;
  approverType?: string;
}

export interface AgentTopic {
  id?: string;
  tenantId?: string;
  projectId: string;
  name: string;
  description?: string;
  instructions: string;
  tools?: AgentTopicTools[];
  createdBy?: string | null;
  updatedBy?: string | null;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface InteractiveModeExampleItem {
  role: 'customer' | 'agent';
  content: string;
}

export interface InteractiveModeExample {
  title: string;
  isExternal: boolean;
  items: InteractiveModeExampleItem[];
}

export interface CustomerViewSettings {
  isPrivate?: boolean;
  isPinnedToBottom?: boolean;
  customMessage?: string;
}

export class Tenant {
  disableAutomatedTicketing: boolean;
  closeConversationsAfterHours: number | null;
  closeStaleConversationsMode: string;
  sendRemindersForStaleIntakesAfterHours: number | null;
  assignToTaggedUserEnabled: boolean;
  subdomain: string;
  slackBotId?: string;
  slackBotBotId?: string;
  slackTeamId?: string;
  slackTeamName?: string;
  slackTeamUrl?: string;
  workos?: any;
  id?: string;
  name: string;
  isPublic: boolean;
  logo: string;
  primaryColor: string;
  provider: string;
  authProviders?: AuthProvider[];
  email?: string;
  phone?: string;
  allowedEmailDomains: string[];
  permissions: {
    canCreatePlans?: boolean;
    canCreateOrganizers?: boolean;
    canCreatePosts?: boolean;
  };
  users: {
    phoneNumberRequired?: boolean;
    customProfileFields?: any[];
  };
  defaultFieldSettings?: {
    [id: string]: {
      public?: boolean;
    };
  };
  homePageFilters?: string[];
  accountStatus?: string;
  integrations?: any;
  defaultCommunityId?: string;
  trialEndDate: any;
  isInternal: boolean;
  defaultTriageChannelId?: string;
  autoresponder: {
    enabled: boolean;
    condition: string;
    message: string;
    includeKnowledgeBaseLinks?: string;
    includeAiResponse?: string;
    alternateMessage?: string;
    aiResponsePrefix?: string;
    aiResponseFallback?: string;
  };
  workingHours: {
    enabled: boolean;
    intervals: any[];
    timezone?: string;
  };
  billing?: {
    stripeProductId?: string;
    stripePriceId?: string;
    stripeSubscriptionId?: string;
    restrictedFeatures?: string[];
    upgradeProductId?: string;
    upgradePriceId?: string;
  };
  teamAssignmentLoadBalancingMethod?: string;
  skippedOnboardingSteps?: Record<string, boolean>;
  replyTimeoutMinutes?: number;
  botHandling?: string;
  isUserAutoAssignableDefault: boolean;
  slackAwaySettings: {
    useStatus: boolean;
    useDnd: boolean;
    awayEmojis: string[];
  };
  aiFeatures: AiFeatures;
  supportName?: string;
  supportLogo?: string;
  externalCrmConfig?: any;
  externalTaskManagementConfig?: {
    siteUrl?: string;
    orgId?: string;
    name?: string;
    type?: string;
    orgName?: string;
    projectKeys?: string[];
  };
  customerViewEnabled: boolean;
  customerViewSettings?: CustomerViewSettings | null;
  emojiActionsConfig: Record<string, string>;
  moveToNeedsResponse?: boolean;
  autoSplittingEnabled?: boolean;
  doNotReopenConversationsOnReply?: boolean;
  disableConversationsStartedByInternalUser?: boolean;
  advancedInternalTicketingEnabled?: boolean;
  advancedInternalTicketingDefaultCustomerId?: string;
  customEmojis?: Record<string, string>;
  customEmojisVersion: number;
  isCustomActionsEnabled?: boolean;
  postCloseSettings?: {
    enabled?: boolean;
    slackTemplateId?: string | null;
    ticketTypeIds?: string[];
    sendMaxOnce?: boolean;
  };
  customCustomerFields?: any[];
  isInternalSupport: boolean;
  restrictManualTicketingToAgents: boolean;
  interactiveModeCustomPrompt?: string;
  interactiveModeExamples?: InteractiveModeExample[];
  defaultToPublicMessages?: boolean;
  llmConfig?: {
    type?: string;
    model?: string;
  };
  defaultProjectId?: string;
  accountsEnabled?: boolean;
  useTopicAwareAgent: boolean;
  defaultChannelIntakeSettings?: IntakeSettings;
  botDmIntakeMode?: Exclude<IntakeMode, typeof INTAKE_MODES.IMMEDIATE>;
  viewAgentThoughtsEnabled: boolean;
}

interface SupportStep {
  type: 'assignment' | 'escalation' | 'reminder' | 'triage';
  userId?: string;
  assigneeId?: string;
  assigneeType: EntityType;
  minutes: number;
}

export class Organizer {
  conversationCreationConditions?: any[];
  name: string;
  tagline: string;
  description: string;
  category?: string;
  createdBy: string;
  updatedBy?: string;
  objectId?: string;
  image: string;
  tenantId?: string;
  createdAt: Date;
  updatedAt: Date;
  adminEmails: string[];
  adminIds?: string[];
  admins: string[];
  calendarUrl: string;
  email: string;
  slackChannelId?: string;
  slackChannel?: SlackChannel;
  followerCount: number;
  isPublic: boolean;
  isDiscoverable: boolean;
  showMemberList: string;
  newMemberNotification: string;
  newMemberNotificationOptOutList: string[];
  newMemberWelcomeEmailTemplate?: string;
  eventbriteFeedUrl?: string;
  eventbriteId?: string;
  externalCalendar?: {
    id?: string;
    sync?: string;
    source?: 'microsoft.com' | 'google.com';
    name?: string;
    userId?: string;
  };
  slackEnabled?: boolean;
  teamsEnabled?: boolean;
  syncSlackChannel?: boolean;
  teamsWebhookUrl?: string;
  memberSourceType?: string;
  removed?: boolean;
  customFields?: any[];
  id?: string;
  defaultSettingsEventId?: string;
  supportSteps?: SupportStep[];
  slackTeamId?: string;
  slackTeamName?: string;
  tags?: Tag[];
  replyTimeoutMinutes?: number;
  defaultTriageChannelId?: string;
  disableAutomatedTicketing?: boolean;
  primarySupportAssigneeId?: string;
  primarySupportAssigneeType?: string;
  secondarySupportAssigneeId?: string;
  secondarySupportAssigneeType?: string;
  botHandling?: string;
  autoresponder?: any;
  assignToTaggedUserEnabled?: boolean;
  emailDomains?: string[] | null;
  emailDomainsWrapper?: {
    emailDomains: string[];
  };
  isInternal?: boolean;
  externalCrmMetadata?: any;
  supportStepRuleset?: any;
  supportStepRulesetId?: string;
  customerViewEnabled: boolean | null;
  customerViewSettings?: CustomerViewSettings | null;
  emailDomainTrackingDisabled: boolean;
  projectId?: string;
  intakeMode?: IntakeMode;
  type: ChannelType;
  inboundEmailRule?: Partial<InboundEmailRule>;
  externalChatConnection?: Partial<ExternalChatConnection>;
  widget?: Partial<Widget>;
  intakeSettings?: IntakeSettings;
}

export interface OrganizerFollow {
  organizerId: string;
  customFields?: Record<string, unknown>;
  following?: boolean;
  userId?: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
}

export interface CommunityMember {
  communityId: string;
  userId: string;
  following: boolean;
  customFields?: Record<string, unknown>;
  tenantId: string | null;
  user?: Profile;
  isExternal?: boolean;
}

export interface Tag {
  id: string;
  name: string;
  description: string;
  autoTaggingEnabled: boolean;
}

export interface TicketType {
  id: string;
  name: string;
  description: string;
  prioritySelector: number;
  fields: CustomField[];
  defaultCustomerId?: string;
  defaultSlackChannelId?: string;
  projectId: string;
  tools?: AgentTopicTools[];
  instructions?: string;
}

export interface TicketTypeField {
  id: string;
  name: string;
  type: string;
  options: any[];
  ticketTypeId: string;
  tenantId: string;
  projectId: string;
  ticketType?: TicketType;
}

interface CustomFieldSimpleConditions {
  simple: SimpleConditions;
}

interface CustomFieldAdvancedConditions {
  advanced: string;
}

export interface CustomField {
  id: string;
  label: string;
  description?: string;
  public?: boolean | null;
  required?: boolean | null;
  conditions?: CustomFieldSimpleConditions | CustomFieldAdvancedConditions | null;
  type: string;
}

export interface CollaboratorType {
  id: string;
  name: string;
  description: string;
  key: string;
}

export class Template {
  id: string;
  name: string;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
  type: string;
  chunks?: any;
  design?: any;
  html: string;
  communityId?: string;
  tenantId: string;
  isDiscoverable: boolean;
  image?: string;
  createdBy?: string;
}

export class UserNotification {
  createdAt: Timestamp;
  userId: string;
  tenantId: string;
  read: boolean;
  readAt?: Timestamp;
  image?: string;
  type: string;
  data?: any;
  objectId?: string;
}

export class UnsplashPhoto {
  id: string;
  alt_description: string;
  blur_hash: string;
  categories: string[];
  color: string;
  crearedAt: string;
  current_user_collections: any[];
  description: string;
  height: number;
  liked_by_user: boolean;
  likes: number;
  links: any;
  promoted_at: string;
  sponsorship: any;
  updated_at: string;
  urls: any;
  user: any;
  width: number;
}

export interface ListQueryResult {
  rows: any[];
  count: number;
}

export interface Integration {
  imageSrc: string;
  name: string;
  title: string;
  connect: string;
  preview: string;
}

export interface IntegrationCatalog {
  imageSrc: string;
  url: string;
  key: string;
  name: string;
  title: string;
  bullets: string[];
  html: string;
  connect: string;
  unthreadPreview: string;
}

export interface WhereClause {
  field: string;
  operator: '<' | '<=' | '==' | '!=' | '>=' | '>' | 'contains' | 'in' | 'array-contains-any' | 'notIn' | 'like';
  value: any;
}

export interface ListRequest {
  where?: WhereClause[];
  order?: string[];
  select?: string[];
  limit?: number | null;
  descending?: boolean;
  nextCursor?: string;
  previousCursor?: string;
  [key: string]: any;
}

export interface PgWhereClause {
  field: string;
  operator: '==' | '!=' | '<' | '>' | '<=' | '>=' | 'like';
  value: any;
}

export interface PgListRequest {
  where?: PgWhereClause[];
  order?: string[];
  select?: string[];
  limit?: number | null;
  descending?: boolean;
  cursor?: string;
}

export interface ListResponse<T> {
  data: T[];
  totalCount: number;
  cursors: {
    next?: string;
    previous?: string;
    hasNext: boolean;
    hasPrevious: boolean;
  };
}

export interface Outbound {
  id: string;
  tenantId: string;
  deliveryMethod: String;
  runAt?: Date;
  subject?: string;
  blocks?: any[];
  recipients?: any[];
  communityId?: string;
  sendAs?: any;
  sendToSlackChannel: boolean;
  status: string;
  body: string;
  metadata?: any;
}

export interface ConversationCollaborator {
  conversationId?: string;
  userId?: string;
  groupId?: string;
  entityId?: string;
  tenantId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  user?: Profile;
  group?: UserGroup;
  collaboratorTypeId?: string;
  collaboratorType?: CollaboratorType;
}

export interface ConversationFollower {
  conversationId?: string;
  userId?: string;
  groupId?: string;
  entity?: string;
  tenantId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  user?: Profile;
  group?: UserGroup;
}

export interface Conversation {
  tenantId: string;
  createdAt: Date;
  updatedAt: Date;
  teamId: string;
  channelId: string;
  messageTs: string;
  initialMessage: any;
  latestMessage: any;
  status: string;
  threadTs: string;
  friendlyId: string;
  customerId: string;
  assignedToUserId: string;
  assignedToUser?: Profile;
  id: string;
  wasManuallyCreated?: boolean;
  category: string;
  project?: Partial<Project>;
  respondedAt?: Date;
  responseTime?: number | null;
  responseTimeWorking?: number | null;
  closedAt?: Date;
  resolutionTime?: number | null;
  resolutionTimeWorking?: number | null;
  notes?: string;
  dueDate?: Date;
  priority?: number;
  wakeUpAt?: Date;
  snoozedAt?: Date;
  tags?: Tag[];
  excludeAnalytics?: boolean;
  customer?: {
    name: string;
    slackChannel?: {
      name?: string;
    };
    tags: { id: string; name: string }[];
    customFields?: any[];
  };
  account?: {
    id: string;
    name: string;
    icon?: string;
    tags?: { id: string; name: string }[];
    customFields?: any[];
  };
  summary?: string;
  triageMessages?: any[];
  escalationTier?: number;
  title?: string;
  resolvedTitle?: any[];
  slackChannel?: {
    id: string;
    name: string;
    teamId?: string;
  };
  externalTasks?: {
    id: string;
    name: string;
    remoteId: string;
    friendlyId?: string;
    isCompleted: boolean;
    url?: string;
  }[];
  externalSupportMetadata?: { url: string; type: string };
  statusUpdatedAt?: Date;
  latestTopLevelTs?: string;
  waitingOnAgentTime?: number | null;
  waitingOnAgentTimeWorking?: number | null;
  waitingOnCustomerTime?: number | null;
  waitingOnCustomerTimeWorking?: number | null;
  lockedAt?: Date;
  latestExternalMessage?: {
    id: string;
    timestamp: Date;
  };
  latestInternalMessage?: {
    id: string;
    timestamp: Date;
  };
  ticketTypeId?: string;
  ticketType?: {
    id: string;
    name: string;
    description: string;
    fields: any[];
  };
  ticketTypeFields?: any;
  titleOverwritten?: boolean;
  initialMessageId?: string;
  sentiment?: number;
  language?: string;
  collaborators?: ConversationCollaborator[];
  followers?: ConversationFollower[];
  submitterUser?: {
    name?: string;
    email?: string;
    photo?: string;
    id?: string;
    isExternal?: boolean;
  };
  submitterUserId?: string;
  inboundEmailRuleId?: string;
  inboundEmailRule?: {
    email: string;
  };
  projectId?: string;
  knowledgeBaseArticle?: {
    id: string;
  };
  accountId?: string;
  metadata?: Record<string, any>;
}

export interface ConversationMessage {
  id: string;
  content: string;
  resolvedContent: (string | any)[];
  tenantId: string;
  timestamp: Date;
  conversationId: string;
  ts: string;
  subtype?: string;
  user?: any;
  inbound?: any;
  botId?: string;
  isAutoresponse?: boolean;
  emailId?: string;
  nlpResult?: any;
  userType?: string;
  text?: string;
  blocks?: any[];
  attachments?: any[];
  triageThread?: {
    id: string;
    initialMessage: {
      id: string;
      ts: string;
      slackChannel: {
        id: string;
        name: string;
      };
    };
  };
  slackChannel?: {
    id: string;
    name: string;
  };
  widgetMessageId?: string;
  microsoftTeamsMessageId?: string;
  isPrivateExplanation?: boolean;
  files?: any[];
  survey?: any;
  sourceType?: string;
  deletedAt?: string;
  reactions?: {
    name: string;
    count: number;
    users: string[];
  }[];
  channelId: string;
  isPrivateNote?: boolean;
  manualSplitMessageId?: string;
  htmlContent?: string;
}

export interface ServiceAccount {
  id: string;
  name: string;
  status: string;
  tenantId: string;
  createdAt: Date;
  crreatedBy: string;
  updatedAt: Date;
  updatedBy: string;
  token?: string;
  role: TenantUserRole;
  projects: Partial<Project>[];
}

export interface InboundEmail {
  id: string;
  fromUser?: {
    id: string;
    name: string;
    email: string;
  };
  inboundEmailRuleId: string;
  ccs: any[];
  tos: any[];
  bccs: any[];
  createdAt: Date;
  subject: string;
  htmlContent: string;
  attachments: any[];
  inbound: any;
}

export interface InboundEmailRule {
  id: string;
  tenantId: string | null;
  email: string;
  channelId: string;
  customerId: string;
  createdBy: string | null;
  updatedBy: string | null;
  updatedAt: Date;
  createdAt: Date;
  supportSteps?: any[];
  name?: string;
  slackChannel: string;
  customer?: {
    name: string;
  };
  autoresponder?: {
    enabled: boolean;
    condition: string;
    message: string;
  };
  blocklist?: string[];
  shortId?: string;
  alwaysUseGenericEmailAddress?: boolean;
  projectId?: string;
}

export interface ExternalChatConnection {
  id: string;
  tenantId: string | null;
  type: string;
  channelId: string;
  customerId: string;
  createdBy: string | null;
  updatedBy: string | null;
  updatedAt: Date;
  createdAt: Date;
  supportSteps?: any[];
  name?: string;
  slackChannel: string;
  customer?: {
    name: string;
  };
  autoresponder?: {
    enabled: boolean;
    condition: string;
    message: string;
  };
  oauthId: string;
  channelAllowlistEnabled?: boolean;
  channelAllowlist?: string[];
  providerConfig?: any;
  enabled?: boolean;
  projectId?: string;
}

export interface Widget {
  id: string;
  tenantId: string | null;
  channelId: string;
  customerId: string;
  createdBy: string | null;
  updatedBy: string | null;
  updatedAt: Date;
  createdAt: Date;
  supportSteps?: any[];
  name: string;
  title?: string;
  tagline?: string;
  domains?: string[];
  color?: string;
  bubbleStyle?: string;
  bubblePosition?: string;
  replyTime?: string;
  verificationSecret: string;
  ticketTypesEnabled?: boolean;
  slackChannel?: SlackChannel;
  autoresponder?: {
    enabled: boolean;
    condition: string;
    message: string;
  };
  linkedInboundEmailRuleId?: string;
  isFullPageView?: boolean;
  darkMode?: string;
  projectId?: string;
}

export interface OnboardingProgress {
  isSlackConnected: boolean;
  isCustomerAdded: boolean;
  isAssignmentRuleSet: boolean;
  isTriageChannelSet: boolean;
  isAdminAdded: boolean;
  isEmailConnected: boolean;
  isWidgetConnected: boolean;
  isIntegrationAdded: boolean;
  isTaskManagerConnected: boolean;
  isCRMConnected: boolean;
  isTagAdded?: boolean;
  isArticleAdded?: boolean;
  isAutomationAdded?: boolean;
}

export interface SlackSurvey {
  id?: string;
  buttonLabel?: string;
  type?: 'nps' | 'csat';
  buttonLayout?: 'inline' | 'single';
  postSubmissionMessage?: string;
  selectionOptions?: 'emojiFaces' | 'numbers';
  style?: {
    selectionOptions?: 'emojiFaces' | 'numbers';
  };
}

export interface SlackChannel {
  id: string;
  name: string;
  isPrivate?: boolean;
  isShared?: boolean;
  displayName?: string;
  teamId: string;
}

export interface KnowledgeBaseArticle {
  id: string;
  tenantId: string;
  title: string;
  content: string;
  url?: string;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  userGroupIds?: string[];
  userGroups?: Partial<UserGroup>[];
  slackCxTicketId: string;
}

export interface KnowledgeBaseScrapeJob {
  id: string;
  tenantId: string;
  startUrl: string;
  connectionId: string;
  createdAt: Date;
  updatedAt: Date;
  lastRunAt?: Date;
  apifyRunStatus: string;
  sourceType: string;
  sourceTypeConfig: {
    urlAllowlist: string[];
    urlBlocklist: string[];
    cookie?: string;
    isRecursive: boolean;
    sharedDriveIds?: string[];
    selectedDocumentIds?: string[];
    pageIdsAllowlist?: string[];
    pageIdsBlocklist?: string[];
  };
  result: any;
  userGroupIds?: string[];
}

export interface RunQueryResult {
  response?: string;
  articles?: { id: string; url: string; title: string }[];
  reasoningSteps?: string[];
  ticketDetails?: any;
}

export interface ExternalCrmAccount {
  id: string;
  name: string;
  slackChannelId?: string;
}

export interface ExternalTask {
  id: string;
  name: string;
  status: string;
  url: string;
  remoteId: string;
  projectId: string;
}

export const SIMPLE_CONDITIONS_OPERATORS = {
  IS_NOT_NULL: 'isNotNull',
  IS_NULL: 'isNull',
  EQUALS: 'equals',
  NOT_EQUALS: 'notEquals',
  IN: 'in',
  NOT_IN: 'notIn',
  CONTAINS: 'contains',
  NOT_CONTAINS: 'notContains',
  GREATER_THAN: 'greaterThan',
  LESS_THAN: 'lessThan',
  GREATER_THAN_OR_EQUALS: 'greaterThanOrEquals',
  LESS_THAN_OR_EQUALS: 'lessThanOrEquals',
} as const;

export type SimpleConditionsOperator = (typeof SIMPLE_CONDITIONS_OPERATORS)[keyof typeof SIMPLE_CONDITIONS_OPERATORS];

export interface SimpleConditionsLeaf {
  leftValue?: any;
  leftValueFrom?: string;
  operator?: SimpleConditionsOperator; // leaf can be empty object
  rightValue?: any;
  rightValueFrom?: string;
  values?: any[];
  additionalFields?: {
    type: 'single-select' | 'multi-select'; // todo eng-4131 update this
  };
}

export type SimpleConditions =
  | SimpleConditionsLeaf
  | {
      and: SimpleConditions[];
    }
  | {
      or: SimpleConditions[];
    }
  | {};

export const CONDITIONS_TYPES = {
  SIMPLE: 'simple',
  ADVANCED: 'advanced',
} as const;

export type ConditionsType = (typeof CONDITIONS_TYPES)[keyof typeof CONDITIONS_TYPES];

export interface SimpleConditionsWrapper {
  type: typeof CONDITIONS_TYPES.SIMPLE;
  simple: SimpleConditions;
}

export interface AdvancedConditionsWrapper {
  type: typeof CONDITIONS_TYPES.ADVANCED;
  advanced: string;
}

export type Conditions = SimpleConditionsWrapper | AdvancedConditionsWrapper;

export interface Automation {
  id: string;
  name: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
  updatedBy: string;
  status: string;
  trigger: AutomationTrigger;
  steps: AutomationStep[];
  conditions: any;
}

export interface AutomationRun {
  id: string;
  automationId: string;
  status: string;
  startedAt: Date;
  state?: any;
  input?: any;
  completedAt: Date;
  automation?: Partial<Automation>;
}

export interface SavedReportDateRangeAbsolute {
  type: 'absolute';
  startDate: string;
  endDate: string;
}

export interface SavedReportDateRangeRelative {
  type: 'relative';
  period: 'day' | 'week' | 'month' | 'quarter' | 'year';
  startIndex: number;
  endIndex: number;
}

export type SavedReportDateRange = SavedReportDateRangeAbsolute | SavedReportDateRangeRelative;

export interface SavedReport {
  id: string;
  tenantId: string;
  name: string;
  userIds: string[] | null;
  filters?: Record<string, string[]>;
  dimensions?: string[];
  metric: string;
  dateDimension: string;
  displaySettings?: {
    unitConversion?: number;
    lineSmoothing?: boolean;
    chartStyle?: string;
  };
  dateRange: SavedReportDateRange;
  createdBy: string;
  updatedBy: string;
  updatedAt: Date;
  createdAt: Date;
}

export interface ApprovalRequest {
  status: 'approved' | 'rejected' | 'pending';
  id: string;
  tenantId: string;
  assignedToGroupId?: string;
  assignedToUserId?: string;
  statusChangedAt?: Date;
  conversationId: string;
  title?: string;
  notes?: string;
  createdAt: Date;
  approverUser?: Profile;
  approverGroup?: UserGroup;
}

// Automations

export const AUTOMATION_TRIGGER_TYPES = {
  EVENT: 'event',
  MANUAL: 'manual',
  SLACK_REACTION: 'slackReaction',
  WEBHOOK: 'webhook',
  SCHEDULED: 'scheduled',
  DELAY: 'delay',
} as const;

export type AutomationTriggerType = (typeof AUTOMATION_TRIGGER_TYPES)[keyof typeof AUTOMATION_TRIGGER_TYPES];

export interface EventAutomationTrigger {
  type: typeof AUTOMATION_TRIGGER_TYPES.EVENT;
  data: {
    event: string;
    objectType: string;
    config?: Record<string, any>;
  };
}

export interface ManualAutomationTrigger {
  type: typeof AUTOMATION_TRIGGER_TYPES.MANUAL;
  data: {
    objectType: string;
    inputFields: any[];
  };
}

export interface WebhookAutomationTrigger {
  type: typeof AUTOMATION_TRIGGER_TYPES.WEBHOOK;
  data: Record<string, never>;
}

export interface SlackReactionAutomationTrigger {
  type: typeof AUTOMATION_TRIGGER_TYPES.SLACK_REACTION;
  data: {
    emojis: string[];
  };
}

export interface ScheduledAutomationTrigger {
  type: typeof AUTOMATION_TRIGGER_TYPES.SCHEDULED;
  data: {
    cron: string;
    workingTimeOnly?: boolean;
    timezone?: string;
    startDate?: string;
    endDate?: string;
  };
}

export type AutomationTrigger =
  | EventAutomationTrigger
  | ManualAutomationTrigger
  | WebhookAutomationTrigger
  | SlackReactionAutomationTrigger
  | ScheduledAutomationTrigger;

export const AUTOMATION_ACTION_TYPES = {
  TRIGGER_FUNCTION: 'triggerFunction',
  ADD_CONVERSATION_TAGS: 'addConversationTags',
  REMOVE_CONVERSATION_TAGS: 'removeConversationTags',
  ASSIGN_CONVERSATION: 'assignConversation',
  CHANGE_CONVERSATION_STATUS: 'changeConversationStatus',
  ADD_CONVERSATION_FOLLOWER: 'addConversationFollower',
  REMOVE_CONVERSATION_FOLLOWER: 'removeConversationFollower',
  SET_CONVERSATION_COLLABORATOR: 'setConversationCollaborator',
  SET_CONVERSATION_TICKET_TYPE: 'setConversationTicketType',
  SET_CONVERSATION_PRIORITY: 'setConversationPriority',
  POST_TO_TRIAGE: 'postToTriage',
  SEND_SLACK_REMINDER: 'sendSlackReminder',
  SEND_SLACK_TEMPLATE: 'sendSlackTemplate',
  CREATE_APPROVAL_REQUEST: 'createApprovalRequest',
  LOCK_CONVERSATION: 'lockConversation',
  EXCLUDE_CONVERSATION_FROM_ANALYTICS: 'excludeConversationFromAnalytics',
  SEND_CONVERSATION_REMINDER: 'sendConversationReminder',
  WAIT: 'wait',
  RESTART: 'restart',
  SET_SLACK_USER_GROUP_MEMBERSHIP: 'setSlackUserGroupMembership',
} as const;

export type AutomationActionType = (typeof AUTOMATION_ACTION_TYPES)[keyof typeof AUTOMATION_ACTION_TYPES];

interface BaseAutomationAction {
  id: string;
}

export interface TriggerFunctionAutomationAction extends BaseAutomationAction {
  type: typeof AUTOMATION_ACTION_TYPES.TRIGGER_FUNCTION;
  data: {
    functionName: string;
    code: string;
  };
}

export interface CreateApprovalRequestAutomationAction extends BaseAutomationAction {
  type: typeof AUTOMATION_ACTION_TYPES.CREATE_APPROVAL_REQUEST;
  data: {
    entityType: string;
    entityId: string;
    title?: string;
  };
}

export interface AddConversationFollowerAutomationAction extends BaseAutomationAction {
  type: typeof AUTOMATION_ACTION_TYPES.ADD_CONVERSATION_FOLLOWER;
  data: {
    entityType: string;
    entityId: string;
  };
}

export interface SetConversationPriorityAutomationAction extends BaseAutomationAction {
  type: typeof AUTOMATION_ACTION_TYPES.SET_CONVERSATION_PRIORITY;
  data: {
    priority: number;
  };
}

export interface AddConversationTagsAutomationAction extends BaseAutomationAction {
  type: typeof AUTOMATION_ACTION_TYPES.ADD_CONVERSATION_TAGS;
  data: {
    tagIds: string[];
  };
}

export interface SetConversationTicketTypeAutomationAction extends BaseAutomationAction {
  type: typeof AUTOMATION_ACTION_TYPES.SET_CONVERSATION_TICKET_TYPE;
  data: {
    ticketTypeId: string;
  };
}

export interface SetSlackUserGroupMembershipAutomationAction extends BaseAutomationAction {
  type: typeof AUTOMATION_ACTION_TYPES.SET_SLACK_USER_GROUP_MEMBERSHIP;
  data: {
    userIds: string[];
    slackUserGroupId: string;
  };
}

export interface AssignConversationAutomationAction extends BaseAutomationAction {
  type: typeof AUTOMATION_ACTION_TYPES.ASSIGN_CONVERSATION;
  data: {
    entityId: string;
    entityType: string;
    skipNextWaitIfNoAssignee: boolean;
    isEscalation: boolean;
    cycleThroughTeamMembers: {
      enabled: boolean;
      wait?: any;
      maxCycles?: number;
    };
  };
}

export const SLACK_TEMPLATE_SEND_AS_OPTIONS = {
  SUPPORT_TEAM: 'support_team',
  TICKET_ASSIGNEE: 'ticket_assignee',
} as const;

export interface SendSlackTemplateAutomationAction extends BaseAutomationAction {
  type: typeof AUTOMATION_ACTION_TYPES.SEND_SLACK_TEMPLATE;
  data: {
    templateId: string;
    sendAs: typeof SLACK_TEMPLATE_SEND_AS_OPTIONS;
  };
}

export interface ChangeConversationStatusAutomationAction extends BaseAutomationAction {
  type: typeof AUTOMATION_ACTION_TYPES.CHANGE_CONVERSATION_STATUS;
  data: {
    status: 'open' | 'in_progress' | 'on_hold' | 'closed';
  };
}
export interface PostToTriageAutomationAction extends BaseAutomationAction {
  type: typeof AUTOMATION_ACTION_TYPES.POST_TO_TRIAGE;
  data: {
    channelId: string;
    tag: {
      entityId: string;
      entityType: string;
    };
    templateId?: string;
  };
}

export type AutomationAction =
  | TriggerFunctionAutomationAction
  | CreateApprovalRequestAutomationAction
  | AddConversationTagsAutomationAction
  | SetConversationTicketTypeAutomationAction
  | SetConversationPriorityAutomationAction
  | SendSlackTemplateAutomationAction
  | AddConversationFollowerAutomationAction
  | SetSlackUserGroupMembershipAutomationAction
  | AssignConversationAutomationAction
  | ChangeConversationStatusAutomationAction
  | PostToTriageAutomationAction
  | AssignConversationAutomationAction;

export const AUTOMATION_STEP_TYPES = {
  ACTION: 'action',
  BRANCHES: 'branches',
} as const;

export type AutomationStepType = (typeof AUTOMATION_STEP_TYPES)[keyof typeof AUTOMATION_STEP_TYPES];

export interface ActionAutomationStep {
  type: typeof AUTOMATION_STEP_TYPES.ACTION;
  data: {
    action: AutomationAction;
  };
}

export interface BranchesAutomationStep {
  type: typeof AUTOMATION_STEP_TYPES.BRANCHES;
  data: {
    branches: {
      conditions: Conditions;
      steps: AutomationStep[];
      filters?: Map<FilterOption, { id: string | boolean; label: string; values?: any }[]>;
    }[];
  };
}

export type AutomationStep = ActionAutomationStep | BranchesAutomationStep;

export interface Recipient {
  name?: string;
  email: string;
  editable?: boolean;
}

export interface AutomationFunction {
  createdAt: Date;
  createdBy: string;
  exportedFunctionNames: string[];
  id: string;
  status: string;
  updatedAt: Date;
  updatedBy: string;
  version: number;
  code: string;
}

export interface SavedFilter {
  id: string;
  tenantId?: string | null;
  name: string;
  userIds: string[] | null | undefined;
  filters: any[];
  view: string;
  createdBy: string | null;
  updatedBy: string | null;
  updatedAt: Date;
  createdAt: Date;
}

export interface SlackGroup {
  id: string;
  name: string;
  teamId: string;
  slackHandle: string;
  slackId: string;
}
